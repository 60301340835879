export default {
  getTranslated(translations, appLocale = localStorage.getItem("language")) {
    // let appLocale = localStorage.getItem("language");

    if (translations[appLocale]) {
      return translations[appLocale];
    } else {
      return translations[Object.keys(translations)[0]];
    }
  },

  getNicePrice(price, currency) {
    if (!price || price == undefined) return null;

    if (currency == "HUF") {
      return Math.round(price).toLocaleString("hu-HU") + " HUF";
    }

    return (1 * price).toFixed(2).toLocaleString() + " " + currency;
  },

  isObject(object) {
    return object != null && typeof object === "object";
  },

  isEqualObject(obj1, obj2) {
    var props1 = Object.getOwnPropertyNames(obj1);
    var props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length != props2.length) {
      return false;
    }
    for (var i = 0; i < props1.length; i++) {
      let val1 = obj1[props1[i]];
      let val2 = obj2[props1[i]];
      let isObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (isObjects && !this.isEqualObject(val1, val2)) ||
        (!isObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  },
};
